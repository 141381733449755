import { httpPromise } from "services/common/httpPromise";
import { ComponentConstant, ServiceConstant, AppConstant } from "common/constant";
export var getClientEmployees = function (queryparams) {
    return httpPromise.get("".concat(ServiceConstant.CLIENT_SEARCH_EMPLOYEE_LIST_API).concat(queryparams));
};
export var getSearchManualPayment = function (queryString) {
    return httpPromise.get("".concat(ServiceConstant.MANUAL_PAYMENT_API_PATH, "/SearchManualPayments?").concat(queryString));
};
export var getManualPaymentStatus = function () {
    return httpPromise.get("".concat(ServiceConstant.MANUAL_PAYMENT_API_PATH, "/GetManualPaymentStatuses"));
};
export var getManualPaymentById = function (id) {
    return httpPromise.get("".concat(ServiceConstant.MANUAL_PAYMENT_API_PATH, "/GetManualPaymentById?manualPaymentId=").concat(id));
};
export var getManualPaymentStatusHistoryById = function (id) {
    return httpPromise.get("".concat(ServiceConstant.MANUAL_PAYMENT_API_PATH, "/GetManualPaymentStatusHistory?manualPaymentId=").concat(id));
};
export var SaveReturnToManualPayments = function (groupdata) {
    return httpPromise.post("".concat(ServiceConstant.MANUAL_PAYMENT_API_PATH, "/ReturnManualPaymentToPayReel"), groupdata);
};
export var getSubAccountDropdown = function (clientID, subAccountDescriptionAllowed) {
    return httpPromise.get("".concat(ServiceConstant.CLIENT_PROJECTS_API, "/GetSubAccounts?clientID=").concat(clientID, "&subAccountDescriptionAllowed=").concat(subAccountDescriptionAllowed));
};
export var getExpenseReportStatus = function () {
    return httpPromise.get("".concat(ServiceConstant.CORP_EXPENSE_REPORTS_API_PATH, "/GetExpenseReportStatuses"));
};
export var getAdminDashboardMsginfo = function () {
    return httpPromise.get("".concat(ServiceConstant.CORP_ADMIN_DASHBOARD_API, "/Messages"));
};
export var getAdminAddrssChangeinfo = function () {
    return httpPromise.get("".concat(ServiceConstant.CORP_ADMIN_DASHBOARD_API, "/EmpAddressChangeDetails"));
};
export var SaveAddressChangeComplete = function (groupData) {
    return httpPromise.post("".concat(ServiceConstant.CORP_ADMIN_DASHBOARD_API, "/MarkAddressChangeComplete"), groupData);
};
export var getApproveExpenseReport = function (queryString) {
    return httpPromise.get("".concat(ServiceConstant.CORP_EXPENSE_REPORTS_API_PATH, "/SearchApproveExpenseReports?").concat(queryString), {
        timeout: AppConstant.INVOICE_API_TIMEOUT,
    });
};
export var getApproveManualPayment = function (queryString) {
    return httpPromise.get("".concat(ServiceConstant.MANUAL_PAYMENT_API_PATH, "/SearchApproveManualPayments?").concat(queryString));
};
export var getDocumentChangesList = function () {
    return httpPromise.get("".concat(ServiceConstant.CORP_ADMIN_DASHBOARD_API, "/GetDocumentChanges"));
};
export var getNewW4CompletedList = function () {
    return httpPromise.get("".concat(ServiceConstant.CORP_ADMIN_DASHBOARD_API, "/GetNewW4Completed"));
};
export var postManualPaymentListForApproval = function (dataObj) {
    return httpPromise.post("".concat(ServiceConstant.MANUAL_PAYMENT_API_PATH, "/ApproveManualPayments"), dataObj);
};
export var getAgreementChangesList = function () {
    return httpPromise.get("".concat(ServiceConstant.CORP_ADMIN_DASHBOARD_API, "/GetPendingTempAgreements"));
};
export var getClientEmployeesinfo = function (id) {
    return httpPromise.get("".concat(ServiceConstant.CORP_EMPLOYEE_API_PATH, "/ViewEmployeeInfo?employeeID=").concat(id));
};
export var getClientEmployeeWebsettings = function (id) {
    return httpPromise.get("".concat(ServiceConstant.CORP_EMPLOYEE_API_PATH, "/ViewEmployeeWebSettings?employeeID=").concat(id));
};
export var getClientEmployeePosition = function (querystring) {
    return httpPromise.get("".concat(ServiceConstant.CORP_EMPLOYEE_API_PATH, "/ViewEmployeePositionRates?").concat(querystring));
};
export var getVoidedInvoicesList = function () {
    return httpPromise.get("".concat(ServiceConstant.VOID_INVOICE_API_PATH, "/GetVoidedInvoices"));
};
export var SaveVoidedInvoicesComplete = function (groupData) {
    return httpPromise.post("".concat(ServiceConstant.VOID_INVOICE_API_PATH, "/VoidInvoicesComplete"), groupData);
};
export var getPaycardChangesList = function () {
    return httpPromise.get("".concat(ServiceConstant.CORP_ADMIN_DASHBOARD_API, "/GetPaycardDocumentChanges"));
};
export var getPendingSHTCList = function () {
    return httpPromise.get("".concat(ServiceConstant.CORP_ADMIN_DASHBOARD_API, "/GetPendingSHTC"));
};
export var exportSHTCData = function () {
    return httpPromise.get("".concat(ServiceConstant.CORP_ADMIN_DASHBOARD_API, "/ExportSHTCData"));
};
export var getAdminEmployeeList = function (queryString) {
    return httpPromise.get("".concat(ServiceConstant.CORP_EMPLOYEE_API_PATH, "/AdminEmployeeSearch?").concat(queryString));
};
export var getManagerEmployeeList = function (queryString) {
    return httpPromise.get("".concat(ServiceConstant.CORP_CLOCKMGMT_API_PATH, "/SearchTimeClock?").concat(queryString));
};
export var getAdminEmployeeEditList = function (queryString) {
    return httpPromise.get("".concat(ServiceConstant.CORP_EMPLOYEE_API_PATH, "/EditEmployeeInfo?").concat(queryString));
};
export var updateEmployeeInfo = function (groupData) {
    return httpPromise.post("".concat(ServiceConstant.CORP_EMPLOYEE_API_PATH, "/UpdateEmployeeInfo"), groupData);
};
export var approveExpenseReportInBatch = function (dataObj) {
    return httpPromise.post("".concat(ServiceConstant.CORP_EXPENSE_REPORTS_API_PATH, "/ApproveExpenseReports"), dataObj);
};
export var getPayReelUserList = function (queryString) {
    return httpPromise.get("".concat(ServiceConstant.CORP_ADMIN_DASHBOARD_API, "/GetPayReelUsers?").concat(queryString));
};
export var getUnsignedVendorPayments = function (clientId) {
    return httpPromise.get("".concat(ServiceConstant.SEARCH_VENDORS_API_PATH, "/GetUnsignedVendorPaymentsDT?clientId=").concat(clientId));
};
export var getAdminEmployeeWebSetting = function (queryString) {
    return httpPromise.get("".concat(ServiceConstant.CORP_EMPLOYEE_API_PATH, "/EditEmployeeWebSettings?").concat(queryString));
};
export var updateEmployeeWebSettings = function (profileData) {
    return httpPromise.post("".concat(ServiceConstant.CORP_EMPLOYEE_API_PATH, "/UpdateEmployeeWebSettings"), profileData);
};
export var positionByClientIdDropDown = function (clientId) {
    return httpPromise.get("".concat(ServiceConstant.CORP_EMPLOYEE_API_PATH, "/PositionsByClientId?clientId=").concat(clientId));
};
export var postAssignVendorID = function (employeeID, clientID) {
    return httpPromise.post("".concat(ServiceConstant.CORP_EMPLOYEE_API_PATH, "/AssignVendorIdAsync?employeeID=").concat(employeeID, "&clientId=").concat(clientID));
};
export var getNonWorkedHoursCodeGridData = function (clientID) {
    return httpPromise.get("".concat(ServiceConstant.NON_WORKED_HOURS_CODES_BASE_API, "/GetAllNonWorkedHoursCodes?clientId=").concat(clientID));
};
export var postNonWorkedHoursCode = function (groupData) {
    return httpPromise.post("".concat(ServiceConstant.NON_WORKED_HOURS_CODES_BASE_API, "/SaveNonWorkedHoursCode"), groupData);
};
export var getClientWorkLocations = function (groupdata) {
    return httpPromise.get("".concat(ServiceConstant.GET_WORK_LOCATION_API_PATH, "/SearchClientWorkLocations?").concat(groupdata));
};
export var getExpenseCodeGridData = function (clientID) {
    return httpPromise.get("".concat(ServiceConstant.EXPENSE_CODE_BASE_API, "/GetAllExpenseCodesByClientId?clientId=").concat(clientID));
};
export var saveAddorEditExpenseCodeData = function (groupdata) {
    return httpPromise.post("".concat(ServiceConstant.EXPENSE_CODE_BASE_API, "/SaveExpenseCode"), groupdata);
};
export var getProOnboardingEmployees = function (queryParams) {
    return httpPromise.get("".concat(ServiceConstant.CORP_EMPLOYEE_API_PATH, "/SearchProAndOnboardingEmployees?").concat(queryParams));
};
export var getClientsForMasterClients = function (masterClientId) {
    return httpPromise.get("".concat(ServiceConstant.MASTER_CLIENT_API_BASE_PATH, "/GetClientsDtForMasterClient?masterClientId=").concat(masterClientId));
};
export var getCountriesList = function () {
    return httpPromise.get("".concat(ServiceConstant.GET_COUNTRIES_API_PATH));
};
export var getStateFromCountry = function (countryID) {
    return httpPromise.get("".concat(ServiceConstant.CORP_STATE_API_PATH, "/GetStateFromCountry?countryID=").concat(countryID));
};
export var postWorkLocationForSetup = function (groupdata) {
    return httpPromise.post("".concat(ServiceConstant.GET_WORK_LOCATION_API_PATH, "/SaveWorkLocationForSetup"), groupdata);
};
export var saveInactivateEmployees = function (clientID) {
    return httpPromise.post("".concat(ServiceConstant.CORP_EMPLOYEE_API_PATH, "/InActivateClientEmployee?clientId=").concat(clientID));
};
export var getPaidSickLeaveRules = function (groupData) {
    return httpPromise.get("".concat(ServiceConstant.PAID_SICK_LEAVE_API_PATH, "/SearchRules?").concat(groupData));
};
export var getNewHirePerformanceCompliances = function (clientId) {
    return httpPromise.get("".concat(ServiceConstant.NEW_HIRE_DOCUMENT_TYPE_CORP_API_BASE_PATH, "/GetNewHireDocTypesForPerformanceCompliance?clientId=").concat(clientId));
};
export var addNewHirePerformanceCompliances = function (groupData) {
    return httpPromise.post("".concat(ServiceConstant.NEW_HIRE_DOCUMENT_TYPE_CORP_API_BASE_PATH, "/AddNewHireDocTypesForPerformanceCompliance"), groupData);
};
export var saveNewHirePerformanceCompliances = function (groupData) {
    return httpPromise.post("".concat(ServiceConstant.NEW_HIRE_DOCUMENT_TYPE_CORP_API_BASE_PATH, "/SaveNewHireDocTypeForPerformanceCompliance"), groupData);
};
export var approveExpenseReportPerDiemAmount = function (dataObj) {
    return httpPromise.post("".concat(ServiceConstant.CORP_EXPENSE_REPORTS_API_PATH, "/ApproveExpenseReportPerDiemAmount"), dataObj);
};
export var getAdminEmployeePositionGridData = function (employeeId, clientId) {
    return httpPromise.get("".concat(ServiceConstant.CORP_EMPLOYEE_API_PATH, "/EditEmployeePostions?EmployeeID=").concat(employeeId, "&ClientID=").concat(clientId));
};
export var getPaidSickLeaveRulesById = function (ruleId) {
    return httpPromise.get("".concat(ServiceConstant.PAID_SICK_LEAVE_API_PATH, "/GetRuleById?ruleId=").concat(ruleId));
};
export var getEligibleSlamStates = function () {
    return httpPromise.get("".concat(ServiceConstant.PAID_SICK_LEAVE_API_PATH, "/EligibleSlamState"));
};
export var postPaidSickLeaveRules = function (groupData) {
    return httpPromise.post("".concat(ServiceConstant.PAID_SICK_LEAVE_API_PATH, "/SaveNewRule?").concat(groupData));
};
export var getGeneralLiabilityBaseRate = function (groupData) {
    return httpPromise.get("".concat(ServiceConstant.GENERAL_LIABILITY_BASE_RATE_API_PATH, "/GetRates?").concat(groupData));
};
export var postGeneralLiabilityBaseRates = function (groupData) {
    return httpPromise.post("".concat(ServiceConstant.GENERAL_LIABILITY_BASE_RATE_API_PATH, "/SaveRates"), groupData);
};
export var getManualPaymentsbyStatus = function (queryString) {
    return httpPromise.get("".concat(ServiceConstant.MANUAL_PAYMENT_API_PATH, "/GetManualPaymentsForPayReel?").concat(queryString));
};
export var deleteManualPayments = function (manualPaymentId) {
    return httpPromise.delete("".concat(ServiceConstant.MANUAL_PAYMENT_API_PATH, "/?manualPaymentId=").concat(manualPaymentId));
};
export var postSaveLocationMapping = function (groupData) {
    return httpPromise.post("".concat(ServiceConstant.GET_WORK_LOCATION_API_PATH, "/SaveLocationMapping"), groupData);
};
export var getClientExpenseRate = function (expenseRateId) {
    return httpPromise.get("".concat(ServiceConstant.CORP_CLIENT_EXPENSE_RATE_BASE_API, "/GetClientExpenseRates?clientId=").concat(expenseRateId));
};
export var saveClientExpenseRate = function (groupData) {
    return httpPromise.post("".concat(ServiceConstant.CORP_CLIENT_EXPENSE_RATE_BASE_API, "/SaveClientExpenseRate"), groupData);
};
export var postImportPaidSickLeaveRule = function (groupData) {
    return httpPromise.post("".concat(ServiceConstant.PAID_SICK_LEAVE_API_PATH, "/BulkImportPaidSickLeaveRule"), groupData);
};
export var getClientEquipmentRate = function (equipmentRateId) {
    return httpPromise.get("".concat(ServiceConstant.CORP_CLIENT_EQUIPMENT_RATE_BASE_API, "/GetClientEquipmentRates?clientId=").concat(equipmentRateId));
};
export var saveClientEquipmentRate = function (groupData) {
    return httpPromise.post("".concat(ServiceConstant.CORP_CLIENT_EQUIPMENT_RATE_BASE_API, "/SaveClientEquipmentRate"), groupData);
};
export var getClientAdminRates = function (clientId) {
    return httpPromise.get("".concat(ServiceConstant.CLIENT_ADMIN_RATE_API_BASE_PATH, "/GetClientAdminRates?clientId=").concat(clientId));
};
export var postClientAdminRates = function (groupData) {
    return httpPromise.post("".concat(ServiceConstant.CLIENT_ADMIN_RATE_API_BASE_PATH, "/SaveClientAdminRate"), groupData);
};
export var getClientVendorPaymentRate = function (VendorPaymentRateId) {
    return httpPromise.get("".concat(ServiceConstant.CLIENT_ADMIN_VENDOR_PAYMENT_RATE_API_BASE_PATH, "/GetClientVendorPaymentRates?clientId=").concat(VendorPaymentRateId));
};
export var saveClientVendorPaymentRate = function (groupData) {
    return httpPromise.post("".concat(ServiceConstant.CLIENT_ADMIN_VENDOR_PAYMENT_RATE_API_BASE_PATH, "/SaveClientVendorPaymentRate"), groupData);
};
export var getSUTAMarkupRate = function (clientId) {
    return httpPromise.get("".concat(ServiceConstant.CLIENT_ADMIN_SUTA_MARKUP_API_BASE_PATH, "/GetClientSutaMarkups?clientId=").concat(clientId));
};
export var saveSUTAMarkupRates = function (groupData) {
    return httpPromise.post("".concat(ServiceConstant.CLIENT_ADMIN_SUTA_MARKUP_API_BASE_PATH, "/SaveClientSutaMarkup"), groupData);
};
export var saveManualPayments = function (groupData) {
    return httpPromise.post("".concat(ServiceConstant.MANUAL_PAYMENT_API_PATH, "/SaveManualPayments"), groupData);
};
export var getManualPaymentDetailsById = function (manualPaymentId) {
    return httpPromise.get("".concat(ServiceConstant.MANUAL_PAYMENT_API_PATH, "/getManualPaymentDetailsById?manualPaymentId=").concat(manualPaymentId));
};
export var getAllEarningCodes = function (groupData) {
    return httpPromise.get("".concat(ServiceConstant.EARNING_CODE_API_BASE_PATH, "/GetAllEarningCodes?").concat(groupData));
};
export var getAdminFicaRateList = function () {
    return httpPromise.get("".concat(ServiceConstant.ADMIN_FICA_RATE_API_BASE_PATH, "/GetFederalFICARates"));
};
export var saveAdminFicaRate = function (queryString) {
    return httpPromise.post("".concat(ServiceConstant.ADMIN_FICA_RATE_API_BASE_PATH, "/SaveFederalFICARate?").concat(queryString));
};
export var getClientGeneralLiabilityMarkUp = function (clientGeneralLiabilityMarkupID) {
    return httpPromise.get("".concat(ServiceConstant.CLIENT_ADMIN_GENERAL_LIABILITY_MARKUP_API_BASE_PATH, "/GetClientGeneralLiabilityMarkups?clientId=").concat(clientGeneralLiabilityMarkupID));
};
export var saveClientGeneralLiabilityMarkUp = function (groupData) {
    return httpPromise.post("".concat(ServiceConstant.CLIENT_ADMIN_GENERAL_LIABILITY_MARKUP_API_BASE_PATH, "/SaveClientGeneralLiabilityMarkup"), groupData);
};
export var saveEmpPositionRate = function (groupdata) {
    return httpPromise.post("".concat(ServiceConstant.CORP_EMPLOYEE_API_PATH, "/SaveEmployeePositionRate"), groupdata);
};
export var saveEmpPositionCreateManualPaymentRateDifference = function (groupdata) {
    return httpPromise.post("".concat(ServiceConstant.CORP_EMPLOYEE_API_PATH, "/CreateManualPaymentRateDifference"), groupdata);
};
export var getEmployeePositionRateById = function (employeePositionRateID) {
    return httpPromise.get("".concat(ServiceConstant.CORP_EMPLOYEE_API_PATH, "/GetEmployeePositionRateById?employeePositionRateID=").concat(employeePositionRateID));
};
export var getAdminFutaSutaRatesList = function (stateId, type) {
    var apiUrl = "".concat(ServiceConstant.CORP_API_BASE_PATH, "/");
    switch (type) {
        case ComponentConstant.FUTA_RATES:
            apiUrl += "FutaBaseRate?stateId=".concat(stateId);
            break;
        case ComponentConstant.SUTA_RATES:
            apiUrl += "SutaBaseRate?stateId=".concat(stateId);
            break;
        case ComponentConstant.WC_RATES:
            apiUrl += "WCBaseRate?stateId=".concat(stateId);
            break;
        default:
            break;
    }
    return httpPromise.get(apiUrl);
};
export var saveAdminFutaSutaRates = function (groupData) {
    var apiUrl = groupData.type === ComponentConstant.FUTA_RATES
        ? "".concat(ServiceConstant.ADMIN_FUTA_RATE_API_BASE_PATH)
        : "".concat(ServiceConstant.ADMIN_SUTA_RATE_API_BASE_PATH);
    if ((groupData.stateFutaBaseRateID !== null && groupData.stateFutaBaseRateID > 0) ||
        (groupData.stateSutaBaseRateID !== null && groupData.stateSutaBaseRateID > 0)) {
        return httpPromise.put(apiUrl, groupData);
    }
    else {
        return httpPromise.post(apiUrl, groupData);
    }
};
export var getCompCodeList = function (stateId) {
    return httpPromise.get("".concat(ServiceConstant.ADMIN_WC_RATE_API_BASE_PATH, "/WorkersCompCodes?stateId=").concat(stateId));
};
export var getWorkersCompCodeID = function (stateWCBaseRateID) {
    return httpPromise.get("".concat(ServiceConstant.ADMIN_WC_RATE_API_BASE_PATH, "/WCBaseRateById?stateWCBaseRateId=").concat(stateWCBaseRateID));
};
export var saveAdminWcRates = function (queryString, stateWCBaseRateID) {
    var apiUrl = "".concat(ServiceConstant.ADMIN_WC_RATE_API_BASE_PATH, "?").concat(queryString);
    if (stateWCBaseRateID > 0) {
        return httpPromise.put(apiUrl);
    }
    else {
        return httpPromise.post(apiUrl);
    }
};
export var getClientWCMarkup = function (clientId) {
    return httpPromise.get("".concat(ServiceConstant.CLIENT_ADMIN_WC_MARKUP_API_BASE_PATH, "/GetClientWorkersCompMarkups?clientId=").concat(clientId));
};
export var postClientWCMarkup = function (groupDate) {
    return httpPromise.post("".concat(ServiceConstant.CLIENT_ADMIN_WC_MARKUP_API_BASE_PATH, "/SaveClientWorkersCompMarkup"), groupDate);
};
export var getSexualHarrasmentTrainingRequirementsList = function (stateId) {
    var apiUrl = "".concat(ServiceConstant.CORP_STATE_API_PATH, "/GetStateSHTCRules?stateId=").concat(stateId);
    return httpPromise.get(apiUrl);
};
export var getSexualHarrasmentTrainingRequirementsByShtcID = function (stateSHTCRuleID) {
    var apiUrl = "".concat(ServiceConstant.CORP_STATE_API_PATH, "/GetStateSHTCRuleByID?stateSHTCRuleID=").concat(stateSHTCRuleID);
    return httpPromise.get(apiUrl);
};
export var saveSexualHarrasmentTrainingRequirements = function (shtcData) {
    var apiUrl = "".concat(ServiceConstant.CORP_STATE_API_PATH, "/");
    apiUrl += "AddUpdateStateSHTCRules";
    return httpPromise.post(apiUrl, shtcData);
};
export var deleteManagerEmployeeDetails = function (timeSheetDayDetailid) {
    return httpPromise.delete("".concat(ServiceConstant.CORP_CLOCKMGMT_API_PATH, "/DeleteTimeClock?timeSheetDayDetailid=").concat(timeSheetDayDetailid));
};
